import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const FAQ = () => (
	<Layout headerColor='yellow'>
      <SEO title="Home" />

      <h1>FAQs Coming Soon</h1>
  	</Layout>
)

export default FAQ;